import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import postApiCall from "../../Services/postApiCall";


const Login = () => {
  const [request, setRequestParms] = useState({})
  const [validation, setValidation] = useState({})
  const [forgotEmail, setForgotEmail] = useState('')
  const myRefname= useRef(null);
  const myRefname1= useRef(null);
  
  

  useEffect(() => {
    
  }, [])
  
	const setRequest = (name, value) => {
		value=value?.target?.value;
		
		setRequestParms({ ...request, [name]: value })
	}
	
	const submitHandler = async (evt) => {
		try {
		  evt.preventDefault();
		  
			let data1 = await postApiCall('user/login', request)
			
			const { message, data, status } = data1
			if (status) {
				toast.success(message);
				sessionStorage.setItem('loginDetails', data.token);
				sessionStorage.setItem('userDetails', JSON.stringify(data));
				setTimeout(() => {
					window.location.href = '/demo-test';
				}, 2000);
				
			}else {
				toast.error(message);
				return false
			}
		} catch (err) {
		  const { message } = err.response.data
		  toast.error(message);
		}
	}
	
	const handleClick = () => {
		if(forgotEmail!='' && forgotEmail!=undefined)
		{
			submitForgotPassword();
		}else{
			toast.error("Enter your email");
		}
	}
	
	const submitForgotPassword = async () => {
		try {
			
			let request1={email:forgotEmail}
			let data1 = await postApiCall('user/forgot-password', request1)
			
			const { message, status } = data1
			if (status) {
				toast.success(message);
				myRefname.current.click();
				
			}else {
				toast.error(message);
				return false
			}
		} catch (err) {
		  const { message } = err.response.data
		  toast.error(message);
		}
	}
	
	const submitHandlerVerify = async (evt) => {
		try {
			evt.preventDefault();
			
			let otp=request?.otp1+request?.otp2+request?.otp3+request?.otp4+request?.otp5+request?.otp6;
			let request1={email:forgotEmail,otp:otp}
			let data1 = await postApiCall('user/verify-otp', request1)
			
			const { message,data, status } = data1
			if (status) {
				toast.success(message);
				sessionStorage.setItem('loginDetails', data.token);
				setTimeout(() => {
					window.location.href = '/create-password';
				}, 2000);
				
			}else {
				toast.error(message);
				return false
			}
		} catch (err) {
		  const { message } = err.response.data
		  toast.error(message);
		}
	}
	
	


  return (
	<>
	<ToastContainer/>
    <section class="login-wrap">
    <div class="container-fluid">
        <div class="row wlcome-loging-logo">
        <div class="col-12 col-sm-12 col-md-12">
            <a href="/dashboard"><img src="images/logo-w.png"/></a>
            </div>
        </div>
            <div class="form-box">
                <div class="row align-items-center">
                    <div class="col-12 col-sm-6 col-md-6  col-lg-6 col-xl-8">
                    <div class="wlcome-login">
                        <div class="wlcome-dec">
                        <h2 class="text-white mb-5">Welcome To UserTest Pro!</h2>
                            
<div id="demo" class="carousel slide" data-bs-ride="carousel">

  <div class="carousel-indicators">
    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
  </div>
  
  <div class="carousel-inner">
    <div class="carousel-item active">
      <h4 class="text-white">Companies need your feedback</h4>
                            <p class="mb-5">Creating outstanding products is impossible without a clear understanding of what people want</p>
    </div>
    <div class="carousel-item">
    <h4 class="text-white">That’s where you as a tester come in</h4>
                            <p class="mb-5">Provide your valuable opinions on products by participating in our tests</p>
    </div>
    <div class="carousel-item">
    <h4 class="text-white">You make things better!</h4>
                            <p class="mb-5">Your feedback helps companies make better products</p>
    </div>
  </div>
</div>
                            
                            
                        </div>
                        </div>
                    </div>
                <div class="col-12 col-sm-12 col-md-6  col-lg-6 col-xl-4">
                    <div class="login-white-box shadow-none py-0">
                        <div class="title">
                        <h3>Login</h3>
                            <p>Enter your registered email ID and password</p>
                        </div>
                <form onSubmit={submitHandler} >
                <div class="form-group">
                    <div class="input-group">
  <span class="input-group-text" id="basic-addon1"><img src="images/mail.svg"/></span>
            <input type="text" class="form-ctrl form-control" placeholder="Email ID" onChange={(evt) => setRequest("email", evt)} />
                        </div>
                    </div>
                     <div class="form-group">
                         <div class="input-group">
  <span class="input-group-text" id="basic-addon1"><img src="images/lock.svg"/></span>
            <input type="password" class="form-ctrl form-control" placeholder="Password" onChange={(evt) => setRequest("password", evt)}/>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-6 col-sm-6 col-md-6">
                        <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required=""/>
              <label class="form-check-label label-trms" for="invalidCheck">
                 Save Password
              </label>
              <div class="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
          </div>
                        </div>
                    <div class="col-6 col-sm-6 col-md-6">
                        <div class="form-group forgot-txt">
                <p><a href="#ForgotPasswordTop" data-bs-toggle="offcanvas"> Forgot Password?</a>
                </p>
            </div>
                        </div>
                    </div>
                    
                <div class="form-group">
           <button type="sumbit" class="btn btn-purple btn-submit btn-block">Login</button>
          </div>
         
                    <div class="form-group mb-0 SignUp-txt text-center">
                <p>Create a new account?   <a href="/register">Register</a>
                </p>
            </div>
        </form>
                        
                    </div>
                    </div>
                </div>
        </div>
        <div class="login-mid-img">
        <img src="images/login-phone-screen.png"/>
        </div>
        </div>
		
		

<div class="offcanvas offcanvas-top offcanvas-top-sm" id="ForgotPasswordTop" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-body">
   <div class="form-box">
                    <div class="login-white-box shadow-none">
                        <div class="row justify-content-center">
                        <div class="col-12 col-sm-11 col-md-11 col-lg-10">
                             <div class="title text-center">
                        <h3>Forgot Password</h3>
                            
                            <p>Enter your registered email ID to reset your password</p>
                        </div>
                <form action="#">
             <div class="form-group mb-6">
                    <div class="input-group">
  <span class="input-group-text" id="basic-addon1"><img src="images/mail.svg"/></span>
            <input type="text" class="form-ctrl form-control" placeholder="Email ID" onChange={(evt) => setForgotEmail(evt.target.value)}/>
                        </div>
                    </div>
                    <div class="form-group" onClick={handleClick}>
						<button type="button"  class="btn btn-purple btn-submit btn-block">Submit</button>
					</div>
					<p ref={myRefname} data-bs-toggle="offcanvas" data-bs-target="#OTPTop" ></p>
        </form>
                            </div>
                        </div>
                       
         </div>
        </div>
      
  </div>
</div>

<div class="offcanvas offcanvas-top offcanvas-top-sm" tabindex="-1" id="OTPTop" aria-labelledby="offcanvasTopLabel">
  <div class="offcanvas-body">
   <div class="form-box otp-box">
                    <div class="login-white-box shadow-none">
                        <div class="title text-center">
                        <h3 class="w-100">Verification Code</h3>
                         
                            <p>Enter the verification code sent to your email</p>
                        </div>
                <form onSubmit={submitHandlerVerify}>
             <div class="form-group mb-5">
            <input type="text" class="form-ctrl form-control" placeholder="0" minLength="1" maxLength="1" onChange={(evt) => setRequest("otp1", evt)}/>
            <input type="text" class="form-ctrl form-control" placeholder="0" minLength="1" maxLength="1" onChange={(evt) => setRequest("otp2", evt)}/>
            <input type="text" class="form-ctrl form-control" placeholder="0" minLength="1" maxLength="1" onChange={(evt) => setRequest("otp3", evt)}/>
            <input type="text" class="form-ctrl form-control" placeholder="0" minLength="1" maxLength="1" onChange={(evt) => setRequest("otp4", evt)}/>
            <input type="text" class="form-ctrl form-control" placeholder="0" minLength="1" maxLength="1" onChange={(evt) => setRequest("otp5", evt)}/>
            <input type="text" class="form-ctrl form-control" placeholder="0" minLength="1" maxLength="1" onChange={(evt) => setRequest("otp6", evt)}/>
            </div>
           
           <div class="form-group resend-otp text-center flex-wrap">
            <p class="w-100">Didn’t receive an OTP?</p>
            <p class="w-100 text-purple">Resend OTP <span id="timer"> </span></p>
            </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-8 col-md-8 col-lg-8">
                            <div class="form-group">
           <button type="submit" class="btn btn-purple btn-submit btn-block">Submit</button>
                            </div>
                        </div>
                    </div>
        </form>
         </div>
        </div>
      
  </div>
</div>

		
    </section>
	</>
  )
}

export default Login