import Axios from "axios";
//import swal from 'sweetalert';
import Constant from "../Components/Constant";
import loginUser from "./loginUser";
import {toast} from "react-toastify";

// 1)api url 2) post parameter 3)paramter for any this return or send alert : if true then function return value
export default function postApiCall(Url, bodyData, isReturn){
    let {accessToken} = loginUser();
   return Axios.post(Constant.apiBasePath + Url,bodyData,{ headers: { Authorization: 'Bearer '+accessToken }})
        .then(data => {
            //console.log('post api hook success',  data.data);
            if(isReturn == undefined){
            if(!data.data.status){
                toast.error(data.data.message);
            }
            return data.data;
        }else{
            if(!data.data.status){
                toast.error(data.data.message);
            }
            return data.data;
        }

        })
        .catch(err => {
			if(err.response.status === 401){
                sessionStorage.clear();
                window.location.href = '/';
                return false;
            }
            if(err.response.data.message != undefined){
                // alert(err.response.data.message);
                return err.response.data;
            }else{
                alert(err.response.data.message);
                return false;
            }
        })
     
}


