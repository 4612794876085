import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import postApiCall from "../../Services/postApiCall";


const DeleteAccount = () => {
  const [request, setRequestParms] = useState({})
  

  useEffect(() => {
		
  }, [])
  
	const setRequest = (name, value) => {
		value=value?.target?.value;
		
		setRequestParms({ ...request, [name]: value })
	}
	
	const submitHandler = async (evt) => {
		try {
		  evt.preventDefault();
		  
			let data = await postApiCall('user/delete-account-req', request)
			
			const { message, status } = data
			console.log("datadatadata",message);
			if (status) {
				toast.success(message);
				
			} else {
				toast.error(message);
				return false
			}
		} catch (err) {
		  const { message } = err.response.data
		  toast.error(message);
		}
	}
  
	


  return (
	<>
		<ToastContainer/>
		 <div>
            <div class="dashboardMain">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-6">
                <div class="form-box">
                    <div class="login-white-box">
                        <div class="title">
                        <h4 class="fw-bold">Account Deletion</h4>
                            <p>Delete your account! We are sorry to see you go. Please provide some information below to request us to delete your account permanently.</p>
                        </div>
                <form action="#">
                <div class="form-group">
                    <div class="input-group">
  <span class="input-group-text" id="basic-addon1"><img src="images/mail.svg" /></span>
            <input type="text" class="form-ctrl form-control" placeholder="Enter Email Address*" onChange={(evt) => setRequest("email", evt)}/>
                        </div>
                    </div>
                <div class="form-group mb-0" onClick={submitHandler}>
           <button type="sumbit" class="btn btn-purple btn-submit btn-block">Submit</button>
          </div>
        </form>
                        
                    </div>
                    </div>
                </div>
            </div>
            </div>
    </div>
	

</>
  )
}

export default DeleteAccount