import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import postApiCall from "../../Services/postApiCall";
import getApiCall from "../../Services/getApiCall";
import $ from "jquery";
import moment from 'moment';


const Register = () => {
  const [request, setRequestParms] = useState({})
  const [validation, setValidation] = useState({})
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const myRefname= useRef(null);

  useEffect(() => {
    getStateList();
	
  }, [])
  
	async function getStateList() {
        let data = await getApiCall('master/state', {}, true);
        if (data.status) {
			setStates(data.data);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }
	
	async function getCityList(stateId) { 
        let data = await getApiCall('master/city?stateId='+stateId, {}, true);
        if (data.status) {
			setCities(data.data);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }

	const setRequest = (name, value) => {
		value=value?.target?.value;
		if(name=='state_id'){
			getCityList(value);
		}
		if(name=="dob")
		{
			value=moment(value).format('YYYY-MM-DD');
		}
		
		setRequestParms({ ...request, [name]: value })
	}
	
	const checkSubmitHandler=()=>{
		
		let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		
		if(request.name=='' || request.name==undefined){
			toast.error("Name is mandatory");
			return false;
		}else if(request.name.length <=3){
			toast.error("Name length is greater than 3");
			return false;
		}else if(request.name.length >=30){
			toast.error("Name length is less than 30");
			return false;
		}else if(request.email=='' || request.email==undefined){
			toast.error("Email is mandatory");
			return false;
		}
		
		if(request.email!='' || request.email!=undefined){
			if (!regEmail.test(request.email)) {
			  toast.error("Invalid Email");
			  return false;
			}
		}
		
		if(request.gender=='' || request.gender==undefined){
			toast.error("Gender is mandatory");
			return false;
		}else if(request.state_id=='' || request.state_id==undefined){
			toast.error("State is mandatory");
			return false;
		}else if(request.city_id=='' || request.city_id==undefined){
			toast.error("City is mandatory");
			return false;
		}else if(request.upi_id=='' || request.upi_id==undefined){
			toast.error("UPI is mandatory");
			return false;
		}else if(request.upi_id.length <=5){
			toast.error("UPI length is greater than 5");
			return false;
		}else if(request.upi_id.length >=30){
			toast.error("UPI length is less than 30");
			return false;
		}else{
			handleClick();
		}
	}
	
	const handleClick = () => {
		
		myRefname.current.click();
	}
	
	const validatePassword = (value) => {
		const isNonWhiteSpace = /^\S*$/;
		if (!isNonWhiteSpace.test(value)) {
			toast.error("Password must not contain Whitespaces.");
			return false;
		}

		const isContainsUppercase = /^(?=.*[A-Z]).*$/;
		if (!isContainsUppercase.test(value)) {
			toast.error("Password must have at least one Uppercase Character.");
			return false;
		}

		const isContainsLowercase = /^(?=.*[a-z]).*$/;
		if (!isContainsLowercase.test(value)) {
			toast.error("Password must have at least one Lowercase Character.");
			return false;
		}

		const isContainsNumber = /^(?=.*[0-9]).*$/;
		if (!isContainsNumber.test(value)) {
			toast.error("Password must contain at least one Digit.");
			return false;
		}

		const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
		if (!isContainsSymbol.test(value)) {
			toast.error("Password must contain at least one Special Symbol.");
			return false;
		}

		const isValidLength = /^.{8,30}$/;
		if (!isValidLength.test(value)) {
			toast.error("Password must be 8-30 Characters Long.");
			return false;
		}
		
		if(request?.confirm_password!=value)
		{
			toast.error("New Password & Confirm Password Not match");
			return false;
		}
		return true;
	}
  
	const submitHandler = async (evt) => {
		try {
		  evt.preventDefault();
		  
			if(request.password!='' || request.password!=undefined){
				if(!validatePassword(request.password))
				{
					return false;
				}
			}
			
			delete request['confirm_password'];
		  
			let data = await postApiCall('user/registration', request)
			const { message, status } = data
			if (status) {
				toast.success(message);
				setTimeout(() => {
					window.location.href = '/login';
				}, 2000);
			} else {
				toast.error(message);
				return false
			}
		} catch (err) {
		  const { message } = err.response.data
		  toast.error(message);
		}
	}
	
	
	

  return (
	<>
	<ToastContainer/>
    <section class="login-wrap">
    <div class="container-fluid">
        <div class="row wlcome-loging-logo">
        <div class="col-12 col-sm-12 col-md-12">
            <a href="/dashboard"><img src="images/logo-w.png"/></a>
            </div>
        </div>
            <div class="form-box">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6  col-lg-6 col-xl-7">
                    <div class="wlcome-login">
                        <div class="wlcome-dec">
                        <h2 class="text-white mb-5">Welcome To UserTest Pro!</h2>
                           <div id="demo" class="carousel slide" data-bs-ride="carousel">

  <div class="carousel-indicators">
    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
  </div>
  
  <div class="carousel-inner">
    <div class="carousel-item active">
      <h4 class="text-white">Companies need your feedback</h4>
                            <p class="mb-5">Creating outstanding products is impossible without a clear understanding of what people want</p>
    </div>
    <div class="carousel-item">
    <h4 class="text-white">That’s where you as a tester come in</h4>
                            <p class="mb-5">Provide your valuable opinions on products by participating in our tests</p>
    </div>
    <div class="carousel-item">
    <h4 class="text-white">You make things better!</h4>
                            <p class="mb-5">Your feedback helps companies make better products</p>
    </div>
  </div>
</div>
                            
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6  col-lg-6 col-xl-5">
                    <div class="login-white-box shadow-none py-0">
                        <div class="title">
                        <h3>Register</h3>
                        </div>
                <form >
                     <div class="form-group">
                    <input type="text" class="form-ctrl form-control" placeholder="Name*" onChange={(evt) => setRequest("name", evt)} />
                    </div>
                    
                    <div class="form-group">
                    <input type="text" class="form-ctrl form-control" placeholder="Email Id*" onChange={(evt) => setRequest("email", evt)} />
                    </div>
                    <div class="row">
                    <div class="col-6 col-sm-6 col-md-6">
                        <div class="form-group">
                            <label>Gender<sup>*</sup></label>
                   <select class="form-ctrl form-control" onChange={(evt) => setRequest("gender", evt)}>
                    <option value="">Select</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Others</option>
                    </select>
                    </div>
                        </div>
                    <div class="col-6 col-sm-6 col-md-6">
                        <div class="form-group calendarInput">
                            <label>Date of birth<sup>*</sup></label>
                <input type="text" class="form-control form-ctrl" name="date" placeholder="Enter*" onBlur={(evt) => setRequest("dob", evt)}/>
                </div>
                        </div>
                    </div>
                    <div class="form-group">
                    <input type="text" class="form-ctrl form-control" placeholder="Country*"  value="India" readonly disabled />
                    </div>
					 <div class="row">
                    <div class="col-6 col-sm-6 col-md-6">
                    <div class="form-group">
                   <select class="form-ctrl form-control" onChange={(evt) => setRequest("state_id", evt)}>
						<option value="">State*</option>
						{  
							(states.length > 0) 
							?
								states.map(el => {
									return <option id={ el.id } value={ el.id }>{ el.name }</option>
								}) 
							:
								''
						}
                    </select>
                    </div>
                    </div>
                         <div class="col-6 col-sm-6 col-md-6">
                    <div class="form-group">
                   <select class="form-ctrl form-control" onChange={(evt) => setRequest("city_id", evt)}>
                    <option value="">City*</option>
					{  
						(cities.length > 0) 
						?
							cities.map(el => {
								return <option id={ el.id } value={ el.id }>{ el.name }</option>
							}) 
						:
							''
					}
                    </select>
                    </div>
                    </div>
                    </div>
                     <div class="form-group">
                <div class="form-group mb-1">
                    <input type="text" class="form-ctrl form-control" placeholder="Enter your UPI ID*" onChange={(evt) => setRequest("upi_id", evt)}/>
                    </div>
                    <p class="small text-danger">Use only  Gpay or PhonePe UPI ID</p>
                    </div>
                    <div class="form-group SignUp-txt text-center">
                <p>Already Have An Account?  <a href="/login">Login</a>
                </p>
            </div>
                <div class="form-group" onClick={checkSubmitHandler} >
           <button type="button"  class="btn btn-purple btn-submit btn-block">Proceed</button>
		   
		   <p ref={myRefname} data-bs-target="#CreatePasswordTop" data-bs-toggle="offcanvas" ></p>
          </div>
         <div class="form-group mb-0 SignUp-txt text-center">
                <p> <a href="#agreeTermsPrivacyModal" data-bs-toggle="modal">Terms of use</a>
                </p>
            </div>
        </form>
                        
                    </div>
                    </div>
                </div>
        </div>
        <div class="login-mid-img register-man">
        <img src="images/register-man-icon.png"/>
        </div>
        </div>
    </section>
	
			   
<div class="offcanvas offcanvas-top offcanvas-top-sm" id="CreatePasswordTop" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-body">
   <div class="form-box">
                    <div class="login-white-box shadow-none">
                             <div class="title text-center">
                        <h3>Create Password</h3>
                            <p>Please create a new password that you have not used previously</p>
                        </div>
                        <form onSubmit={submitHandler} >
                <div class="form-group">
                         <div class="input-group">
  <span class="input-group-text" id="basic-addon1"><img src="images/lock.svg"/></span>
            <input type="password" class="form-ctrl form-control" placeholder="New Password" onChange={(evt) => setRequest("password", evt)}/>
                        </div>
                    </div>
                     <div class="form-group">
                         <div class="input-group">
  <span class="input-group-text" id="basic-addon1"><img src="images/lock.svg"/></span>
            <input type="password" class="form-ctrl form-control" placeholder="Confirm Password" onChange={(evt) => setRequest("confirm_password", evt)}/>
                        </div>
                    </div>
                    <div class="list-point pass-point">
                        <ul class="list-unstyled">
                        <li>At least 8 characters</li>
                        <li>A Combination of both uppercase and lowercase lelers</li>
                        <li>A Combination of letters and numbers</li>
                        <li>At least one special character, e.g., ! @ # ? ]</li>
                        </ul>
                        </div>
                    
                <div class="form-group">
           <button type="submit" class="btn btn-purple btn-submit btn-block">Submit</button>
          </div>
        </form>
                           
                       
         </div>
        </div>
      
  </div>
</div>


<div class="modal fade" id="agreeTermsPrivacyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
        <div class="row justify-content-center">
                          <div class="col-12 col-sm-11 col-md-11">
        <div class="modal-header justify-content-center text-center border-0">
             <h5 class="modal-title w-100">Terms & Conditions</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
           <div class="txt_dec">
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions</p>
               <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions</p>
               <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
          </div>
          <div class="title mb-2 text-center">
          <h4>Privacy Policy</h4>
          </div>
          <div class="txt_dec">
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions</p>
               <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions</p>
               <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
          </div>
        </div>
        </div>
        </div>
          </div>
          </div>
          </div>

	
	</>
  )
}

export default Register