import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import customPostApiCall from "../../Services/customPostApiCall";


const ConfirmDeleteAccount = () => {
  
	let { id } = useParams();
	const [request, setRequestParms] = useState({})
  
	useEffect(() => {
		
	}, [])
	
	
	const submitHandler = async (evt) => {
		try {
		  evt.preventDefault();
		  
		  if (window.confirm("Are you sure you want to delete the account?")) {
			  
			let data = await customPostApiCall('user/delete-account', request, id)
			
			const { message, status } = data
			
			if (status) {
				toast.success(message);
				
			} else {
				toast.error(message);
				return false
			}
		  }
		} catch (err) {
		  const { message } = err.response.data
		  toast.error(message);
		}
	}
	

  return (
	<>
		<ToastContainer/>
		<div class="content">
            <div class="dashboardMain">
				<div class="row justify-content-center">
					<div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-6">
						<div class="form-box">
							<div class="login-white-box">
								<div class="title">
									<h4 class="fw-bold">Confirm Account Deletion</h4>
									<p>Delete your account! We are sorry to see you go. Please provide some information below to request us to delete your account permanently.</p>
								</div>
								<form action="#">
									<div class="form-group mb-0" onClick={submitHandler}>
										<button type="sumbit" class="btn btn-purple btn-submit btn-block">Proceed</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
            </div>
		</div>

</>
  )
}

export default ConfirmDeleteAccount