import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardNav from "../includes/DashboardNav";
import SidebarNav from "../includes/SidebarNav";



const Dashboard = () => {
  const [request, setRequestParms] = useState({})
  const [validation, setValidation] = useState({})
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    
  }, [])


  return (
	<>
		<DashboardNav/>
		<div class="main">
		<SidebarNav/>
		<div class="content">
        <div>
            <div class="dashboardMain">
                    <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div class="media-dashboard media-dashboard-2 shadow-none bg-light-blue">
                <div class="media">
                    <div class="media-left">
                    <div class="dashboard-icon">
                        <img src="images/dashboard-calender-icon.svg"/>
                        </div>
                    </div>
                <div class="media-body">
                    <p class="large mb-0 text-purple">Active Tests</p>
                    <h3 class="mb-0 text-purple">01</h3>
                    </div>
                </div>
                </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div class="media-dashboard media-dashboard-2 shadow-none">
                <div class="media">
                    <div class="media-left">
                    <div class="dashboard-icon">
                        <img src="images/dashboard-calender-iconW.svg"/>
                        </div>
                    </div>
                <div class="media-body">
                    <p class="large mb-0 text-white">Available Tests</p>
                    <h3 class="mb-0 text-white">10</h3>
                    </div>
                </div>
                </div>
                        </div>
                         </div>
                
                <div class="test-dashboard-card">
                <h4 class="d-flex justify-content-between align-items-center">Active Tests <a href="#">View all</a></h4>
            <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                <div class="card test-card">
                <div class="card-header text-center"><div class="countdown" data-date="2028-01-01"></div></div>
                    <div class="card-body">
                    <p class="small"><strong>#54233</strong></p>
                        <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                        <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                        <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                        <div class="grouptwobtns d-flex align-items-center">
                         <a href="#" data-bs-toggle="modal" class="btn btn-block btn-purple">Resume Test</a>
                </div>
                    </div>
                </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                <div class="card test-card">
                <div class="card-header text-center"><div class="countdown" data-date="2028-01-01"></div></div>
                    <div class="card-body">
                    <p class="small"><strong>#54233</strong></p>
                        <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                        <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                        <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                        <div class="grouptwobtns d-flex align-items-center">
                         <a href="#" data-bs-toggle="modal" class="btn btn-block btn-purple">Resume Test</a>
                </div>
                    </div>
                </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                <div class="card test-card">
                <div class="card-header text-center"><div class="countdown" data-date="2028-01-01"></div></div>
                    <div class="card-body">
                    <p class="small"><strong>#54233</strong></p>
                        <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                        <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                        <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                        <div class="grouptwobtns d-flex align-items-center">
                         <a href="#" data-bs-toggle="modal" class="btn btn-block btn-purple">Resume Test</a>
                </div>
                    </div>
                </div>
                </div>
            </div>
            
            </div>
                <div class="test-dashboard-card">
                <h4 class="d-flex justify-content-between align-items-center">Available Tests <a href="#">View all</a></h4>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="media-dashboard bg-white">
                                    <div class="mb-2">
                                        <div class="media-dashboard-header">
                                        <p class="small text-dark"><strong>#54233</strong></p>
                                        </div>
                                         <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                                         <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                        <p><strong class="text-dark">Start Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">Avg Time Required:</strong> 2 Hr, 30 Min</p>
                                    </div>
                    <div class="grouptwobtns d-flex flex-wrap align-items-center">
                         <a href="#GuidelinesNextTop" data-bs-toggle="offcanvas" class="btn btn-block btn-purple w-100 me-0">Start Test</a>
                         <a href="#TestDetailsTop" data-bs-toggle="offcanvas" data-bs-toggle="modal" class="btn btn-block btn-link w-100 me-0">View Details</a>
                </div>
                  </div>
                </div>
                
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="media-dashboard bg-white">
                                    <div class="mb-2">
                                        <div class="media-dashboard-header">
                                        <p class="small text-dark"><strong>#54233</strong></p>
                                        </div>
                                         <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                                         <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                        <p><strong class="text-dark">Start Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">Avg Time Required:</strong> 2 Hr, 30 Min</p>
                                    </div>
                    <div class="grouptwobtns d-flex flex-wrap align-items-center">
                         <a href="#" data-bs-toggle="modal" class="btn btn-block btn-pink w-100 me-0">Available on Mobile</a>
                         <a href="#TestDetailsTop" data-bs-toggle="offcanvas" data-bs-toggle="modal" class="btn btn-block btn-link w-100 me-0">View Details</a>
                </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="media-dashboard bg-white">
                                    <div class="mb-2">
                                        <div class="media-dashboard-header">
                                        <p class="small text-dark"><strong>#54233</strong></p>
                                        </div>
                                         <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                                         <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                        <p><strong class="text-dark">Start Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">Avg Time Required:</strong> 2 Hr, 30 Min</p>
                                    </div>
                    <div class="grouptwobtns d-flex flex-wrap align-items-center">
                         <a href="#GuidelinesNextTop" data-bs-toggle="offcanvas" class="btn btn-block btn-purple w-100 me-0">Start Test</a>
                        <a href="#TestDetailsTop" data-bs-toggle="offcanvas" data-bs-toggle="modal" class="btn btn-block btn-link w-100 me-0">View Details</a>
                </div>
                  </div>
                </div>
            </div>
            
            </div>
                
            </div>
            </div>
    


					
<div class="offcanvas offcanvas-top offcanvas-top-smm" id="TestDetailsTop" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-body">
   <div class="form-box">
             
                             <div class="title d-flex text-center">
                                 <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        <h4 class="w-100">Test Details</h4>
                        </div>
       <div class="row justify-content-center mb-4">
                        <div class="col-10 col-sm-10 col-md-10 col-lg-11">
       <img src="images/test-img.png" class="img-fluid d-block m-auto"/>
           </div>
       </div>
                <form action="/dashboard">
             <div class="form-group">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut</p>
                    </div>
                    <div class="form-group">
           <button type="submit" class="btn btn-purple btn-submit btn-block">Start Test</button>
                    </div>
        </form>
                    
                       
        </div>
      
  </div>
</div>

<div class="offcanvas offcanvas-top offcanvas-top-smm" id="GuidelinesTop" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-body">
   <div class="form-box">
             
                             <div class="title d-flex text-center">
                                 <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        <h4 class="w-100">Guidelines & Tips</h4>
                        </div>
       <div class="row justify-content-center mb-4">
                        <div class="col-10 col-sm-10 col-md-10 col-lg-11">
       <img src="images/GuidelinesTip-img.png" class="img-fluid d-block m-auto"/>
                            <div class="form-group text-center">
                            <p><strong>Please turn on “Do Not Disturb”</strong></p>
                            </div>
           </div>
       </div>
                <form action="#">
             <div class="form-group text-center">
                    <p>To start the test slide open the Control Centre or Notification bar and turn on “Do Not Disturb”.</p>
                    </div>
                    <div class="form-group">
           <button type="button" data-bs-toggle="offcanvas" data-bs-target="#GuidelinesNextTop" class="btn btn-purple btn-submit btn-block">Next</button>
                    </div>
        </form>
                    
                       
        </div>
      
  </div>
</div>

<div class="offcanvas offcanvas-top offcanvas-top-smm" id="GuidelinesNextTop" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-body">
   <div class="form-box">
             
                             <div class="title d-flex text-center">
                                 <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        <h4 class="w-100">Guidelines & Tips</h4>
                        </div>
       <div class="row justify-content-center mb-4">
                       <div class="col-10 col-sm-10 col-md-10 col-lg-11">
       <img src="images/GuidelinesTip-img0.png" class="img-fluid d-block m-auto"/>
                            
           </div>
       </div>
                <form action="#">
             <div class="list-point-number">
                        <ul class="list-unstyled">
                        <li>Find a quiet place with no distractions. </li>
                        <li>Continuously speak your thoughts out loud. </li>
                        <li>Complete each task before moving on to the next.</li>
                        <li>Ensure you have a strong & stable internet connection.</li>
                        </ul>
                        </div>
                    <div class="form-group">
           <button type="button" data-bs-toggle="offcanvas" data-bs-target="#AccessTop" class="btn btn-purple btn-submit btn-block">Next</button>
                    </div>
        </form>
                    
                       
        </div>
      
  </div>
</div>

<div class="offcanvas offcanvas-top offcanvas-top-smm" id="AccessTop" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-body">
   <div class="form-box">
             
                             <div class="title d-flex text-center">
                                 <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        <h4 class="w-100">Access to screen sharing</h4>
                        </div>
       <div class="row justify-content-center mb-4">
                       <div class="col-10 col-sm-10 col-md-10 col-lg-11">
       <img src="images/Access-img.png" class="img-fluid d-block m-auto"/>
                            
           </div>
       </div>
                <form action="#">
             <div class="form-group text-center">
                    <p>Before you can start the test, we need to ask for your permission. Would you like to give permission to allow screen sharing?</p>
                    </div>
                    <div class="form-group text-center">
                            <p><strong>To take part in this test please give permission to allow screen sharing</strong></p>
                            </div>
                    <div class="form-group">
           <button type="button" data-bs-toggle="offcanvas" data-bs-target="#AccessmicrophoneTop" class="btn btn-purple btn-submit btn-block">Enable Access</button>
                    </div>
                    <div class="form-group forgot-txt text-center">
                <p> <a href="#">Do not allow</a>
                </p>
            </div>
        </form>
                    
                       
        </div>
      
  </div>
</div>

<div class="offcanvas offcanvas-top offcanvas-top-smm" id="AccessmicrophoneTop" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-body">
   <div class="form-box">
             
                             <div class="title d-flex text-center">
                                 <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        <h4 class="w-100">Access to microphone</h4>
                        </div>
       <div class="row justify-content-center mb-4">
                        <div class="col-8 col-sm-8 col-md-8 col-lg-8">
       <img src="images/Accessmicrophone-img0.png" class="img-fluid d-block m-auto"/>
                            
           </div>
       </div>
                <form action="#">
             <div class="form-group text-center">
                    <p>Before you can start the test, we need to ask for your permission. Would you like to give permission to access the microphone?</p>
                    </div>
                    <div class="form-group text-center">
                            <p><strong>To take part in this test please give permission to access the microphone</strong></p>
                            </div>
                    <div class="form-group">
           <button type="button" data-bs-toggle="offcanvas" data-bs-target="#MicrophonecheckTop" class="btn btn-purple btn-submit btn-block">Enable Access</button>
                    </div>
                    <div class="form-group forgot-txt text-center">
                <p> <a href="#">Do not allow</a>
                </p>
            </div>
        </form>
                    
                       
        </div>
      
  </div>
</div>

<div class="offcanvas offcanvas-top offcanvas-top-smm" id="MicrophonecheckTop" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-body">
   <div class="form-box">
             
                             <div class="title d-flex text-center">
                                 <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        <h4 class="w-100">Microphone check</h4>
                        </div>
       <div class="row justify-content-center mb-4">
                        <div class="col-10 col-sm-10 col-md-10 col-lg-11">
       <img src="images/Microphonecheck-img.png" class="img-fluid d-block m-auto"/>
                            
           </div>
       </div>
                <form action="/test-question">
             <div class="form-group text-center">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut</p>
                    </div>
                    
                    <div class="form-group">
           <button type="submit" class="btn btn-purple btn-submit btn-block">Start Recording</button>
                    </div>
        </form>        
        </div>
      
  </div>
</div>

		
		</div>
		</div>
   

</>
  )
}

export default Dashboard