import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Logout, userDetails, DeleteAccountRequest } from "../../Services/authenticationService";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const SidebarNav = () => {
  const [request, setRequestParms] = useState({})
  
  
  useEffect(() => {
    
  }, [])


  return (
		<>
		<ToastContainer/>
        <div class="sidebar-header">
			<div class="siderbar-menu">
				<ul class="list-unstyled nav-menu">
					<li class=""><a href="/dashboard"><img src="images/dashboard-icon.svg"/><img class="img-hover" src="images/dashboard-icon-white.svg"/><span>Dashboard</span></a></li>
					<li class=""><a href="/test-history"><img src="images/dashboard-icon0.svg"/><img class="img-hover" src="images/dashboard-icon-white0.svg"/><span>Test History </span></a></li>
					<li class=""><a href="settings"><img src="images/dashboard-icon1.svg"/><img class="img-hover" src="images/dashboard-icon-white1.svg"/><span>Settings</span></a></li>
				
					<li class=""><a href="/contactus"><img src="images/dashboard-icon2.svg"/><img class="img-hover" src="images/dashboard-icon-white2.svg"/><span>Contact Support</span></a></li>
					<li class=""><a href="/aboutus"><img src="images/dashboard-icon3.svg"/><img class="img-hover" src="images/dashboard-icon-white3.svg"/><span>About Us</span></a></li>
					<li class=""><a href="/privacy-policy"><img src="images/dashboard-icon4.svg"/><img class="img-hover" src="images/dashboard-icon-white4.svg"/><span>Privacy Policy</span></a></li>
				
					<li class=""><a href="#" onClick={DeleteAccountRequest}><img src="images/dashboard-icon5.svg"/><img class="img-hover" src="images/dashboard-icon-white5.svg"/><span>Delete Account</span></a></li>
					<li class=""><a href="#" onClick={Logout}><img src="images/logout-purple.svg"/><img class="img-hover" src="images/logout-white.svg"/><span>Log out</span></a></li>      
				</ul>
			</div>
        </div>
		</>
  )
}

export default SidebarNav