import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardNav from "../includes/DashboardNav";
import SidebarNav from "../includes/SidebarNav";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import getApiCall from "../../Services/getApiCall";


const ContactUs = () => {
  const [request, setRequestParms] = useState({})
  const [pages, setPages] = useState({})

	useEffect(() => {
		getPages();
	}, [])
  
	async function getPages() {
        let data = await getApiCall('master/app-info', {}, true);
        if (data.status) {
			setPages(data.data);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }


  return (
	<>
		<DashboardNav/>
		<div class="main">
		<SidebarNav/>
			<div class="content">
			
       <div>
            <div class="dashboardMain">
            <div class="dashboard-boxx">
                <div class="fotr-logo">
                <img src="images/logo.png" alt=""/>
                </div>
		 <div class="media fotr-media">
			<div class="media-left">
			<p><i class="fa fa-phone"></i></p>
			</div>
			<div class="media-body">
			<p>Call us at : <a href="tel:{pages?.phone}">{pages?.phone}</a>  </p>
			</div>
			</div>
                <div class="media fotr-media">
			<div class="media-left">
			<p><i class="fa fa-envelope"></i></p>
			</div>
			<div class="media-body">
			<p>Email us at : <a href="mailto:"> {pages?.email}</a>  </p>
			</div>
			</div>
                <div class="media fotr-media">
			<div class="media-left">
			<p><i class="fa fa-home"></i></p>
			</div>
			<div class="media-body">
			<p>Office Address : {pages?.address}</p>
			</div>
			</div>
                <div class="location-box">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31388301.590735264!2d41.66320656998828!3d16.190606416467837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1677653907173!5m2!1sen!2sin" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                </div>
           
            </div>
</div>


					
<div class="modal fade" id="NewQueryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="row justify-content-center">
                          <div class="col-12 col-sm-11 col-md-11">
        <div class="modal-header justify-content-center border-0">
        <h5 class="modal-title">Add New Query</h5>

      </div>
      <div class="modal-body">
           <div class="form-box form-box-dashboard form-box-medicines">
               <div class="form-box">
                   <div class="form-group">
                       <input type="text" class="form-ctrl form-control" placeholder="Enter name"/>
                   </div>
                  <div class="form-group">
                     <input type="text" class="form-ctrl form-control" placeholder="Number"/>
                   </div>
                   <div class="form-group">
                     <input type="text" class="form-ctrl form-control" placeholder="Email ID"/>
                   </div>
                   <div class="form-group">
                       <textarea rows="4" class="form-ctrl form-control" placeholder="Enter your message"></textarea>
                   </div>
                   <div class="form-group">
                         <div class="btn-width">
                         <button type="submit" class="btn btn-block btn-lg btn-primary btn-radius btn-sumbit">Submit</button>
                         </div>
                         </div>
               </div>
          </div>
        </div>
        </div>
        </div>
          </div>
          </div>
          </div>

		</div>
		
		</div>
   
</>
  )
}

export default ContactUs