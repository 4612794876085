import Axios from "axios";
//import swal from 'sweetalert';
import Constant from "../Components/Constant";
import loginUser from "./loginUser";
import {toast} from "react-toastify";

// 1)api url 2) delete parameter 3) token 4)paramter for any this return or send alert : if true then function return value
export default function customPostApiCall(Url, bodyData={}, accessToken, isReturn){
    
   return Axios.delete(Constant.apiBasePath + Url,{ headers: { Authorization: 'Bearer '+accessToken, params:bodyData}
		})
        .then(data => { 
            if(isReturn == undefined){
            if(!data.data.status){
                toast.error(data.data.message);
            }
            return data.data;
        }else{
            if(!data.data.status){
                toast.error(data.data.message);
            }
            return data.data;
        }

        })
        .catch(err => {
			if(err.response.status === 401){
                return false;
            }
            if(err.response.data.message != undefined){
                return err.response.data;
            }else{
                alert(err.response.data.message);
                return false;
            }
        })
     
}


