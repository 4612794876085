import { toast, ToastContainer } from "react-toastify";
import postApiCall from "../Services/postApiCall";

export async function Logout() {
    // confirm the user wants to logout
    try {
        if (window.confirm("Are you sure you want to logout?")) {
            //let { data } = await postApiCall("/admin/logout", {}, true);
            //if(data.status) {
                sessionStorage.clear();
                window.location.href = '/login';
            //}
        }
    }
    catch(error) {
        console.log(error);
    }
}

export function userDetails() {
    let data = sessionStorage.getItem('userDetails');
    data = JSON.parse(data);
    return data;
}

export async function DeleteAccountRequest() {
    // confirm the user wants to delete account
    try {
        if (window.confirm("Are you sure you want to delete the account?")) {
			let userData = sessionStorage.getItem('userDetails');
			userData = JSON.parse(userData);
	
            let { status, message, data } = await postApiCall("user/delete-account-req", {email:userData.email}, true);
			if(status) {
               toast.success(message);
			}else {
				toast.error(message);
				return false
			}
        }
    }
    catch(error) {
        console.log(error);
    }
}
