import React from 'react'
import {Navigate, Route} from 'react-router-dom'


const WebsiteAccess = ({ element }) => {
    let token = sessionStorage.getItem('loginDetails');
	return token ? element : <Navigate to="/login" />
};


export default WebsiteAccess