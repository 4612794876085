import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardNav from "../includes/DashboardNav";
import SidebarNav from "../includes/SidebarNav";



const TestQuestion = () => {
  const [request, setRequestParms] = useState({})
  const [validation, setValidation] = useState({})
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    
  }, [])


  return (
	<>
		<DashboardNav/>
		<div class="main">
		<SidebarNav/>
			
			 
    <div>
            <div class="dashboardMain">
                <div class="test-dashboard-card">
                <div class="test-card-body">
                <h3 class="mb-0 text-purple-dark">Complete the screener section to start the test</h3>
            </div>
            </div>
                <div class="form-box question-box">
            <div class="login-white-box">
                <div class="arrows_expand">
                <button type="button" class="btn btn-link p-0"><img src="images/arrows_expand.svg"/></button>
                </div>
                <form action="test-question1.php">
                <div class="form-group mb-5">
                <h3 class="mb-4">Question 1/05</h3>
                    <p class="large">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut</p>
                </div>
                <div class="form-group mb-5">
                <div class="form-check mb-3">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
  <label class="form-check-label" for="flexRadioDefault1">
   Dummy Text
  </label>
</div>
               
                <div class="form-check mb-3">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
  <label class="form-check-label" for="flexRadioDefault2">
   Dummy Text
  </label>
</div>
                
                <div class="form-check mb-3">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
  <label class="form-check-label" for="flexRadioDefault3">
   Dummy Text
  </label>
</div>
                
                <div class="form-check mb-3">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"/>
  <label class="form-check-label" for="flexRadioDefault4">
   Dummy Text
  </label>
</div>
                </div>
                <div class="row justify-content-end">
                <div class="col-6 col-sm-4 col-md-4 col-lg-4">
                    <div class="form-group">
           <button type="sumbit" class="btn btn-purple btn-submit btn-block">Next</button>
          </div>
                    </div>
                </div>
                </form>
            </div>
            </div>
            </div>
            </div>
   

		
		</div>
   

</>
  )
}

export default TestQuestion