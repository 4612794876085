import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import postApiCall from "../../Services/postApiCall";


const CreatePassword = () => {
  const [request, setRequestParms] = useState({})
  const [validation, setValidation] = useState({})
 

  useEffect(() => {
    
  }, [])
  
	const setRequest = (name, value) => {
		value=value?.target?.value;
		
		setRequestParms({ ...request, [name]: value })
	}
	
	const validatePassword = (value) => {
		const isNonWhiteSpace = /^\S*$/;
		if (!isNonWhiteSpace.test(value)) {
			toast.error("Password must not contain Whitespaces.");
			return false;
		}

		const isContainsUppercase = /^(?=.*[A-Z]).*$/;
		if (!isContainsUppercase.test(value)) {
			toast.error("Password must have at least one Uppercase Character.");
			return false;
		}

		const isContainsLowercase = /^(?=.*[a-z]).*$/;
		if (!isContainsLowercase.test(value)) {
			toast.error("Password must have at least one Lowercase Character.");
			return false;
		}

		const isContainsNumber = /^(?=.*[0-9]).*$/;
		if (!isContainsNumber.test(value)) {
			toast.error("Password must contain at least one Digit.");
			return false;
		}

		const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
		if (!isContainsSymbol.test(value)) {
			toast.error("Password must contain at least one Special Symbol.");
			return false;
		}

		const isValidLength = /^.{8,16}$/;
		if (!isValidLength.test(value)) {
			toast.error("Password must be 8-16 Characters Long.");
			return false;
		}
		
		if(request?.confirm_password!=value)
		{
			toast.error("New Password & Confirm Password Not match");
			return false;
		}
		return true;
	}
	
	const submitHandler = async (evt) => {
		try {
		  evt.preventDefault();
		  
			if(request.password!='' || request.password!=undefined){
				if(!validatePassword(request.password))
				{
					return false;
				}
			}
			
			delete request['confirm_password'];
		  
			let data = await postApiCall('user/update-password', request)
			const { message, status } = data
			if (status) {
				toast.success(message);
				setTimeout(() => {
					window.location.href = '/dashboard';
				}, 2000);
			} else {
				toast.error(message);
				return false
			}
		} catch (err) {
		  const { message } = err.response.data
		  toast.error(message);
		}
	}


  return (
    <>
	<ToastContainer/>
   <section class="login-wrap">
    <div class="container-fluid">
        <div class="row wlcome-loging-logo">
        <div class="col-12 col-sm-12 col-md-12">
            <a href="dashboard.php"><img src="images/logo-w.png"/></a>
            </div>
        </div>
            <div class="form-box">
                <div class="row align-items-center">
                    <div class="col-12 col-sm-6 col-md-6  col-lg-6 col-xl-8">
                    <div class="wlcome-login">
                        <div class="wlcome-dec">
                        <h2 class="text-white mb-5">Welcome To UserTest Pro!</h2>
                            <div id="demo" class="carousel slide" data-bs-ride="carousel">

  <div class="carousel-indicators">
    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
  </div>
  
  <div class="carousel-inner">
    <div class="carousel-item active">
      <h4 class="text-white">Companies need your feedback</h4>
                            <p class="mb-5">Creating outstanding products is impossible without a clear understanding of what people want</p>
    </div>
    <div class="carousel-item">
    <h4 class="text-white">That’s where you as a tester come in</h4>
                            <p class="mb-5">Provide your valuable opinions on products by participating in our tests</p>
    </div>
    <div class="carousel-item">
    <h4 class="text-white">You make things better!</h4>
                            <p class="mb-5">Your feedback helps companies make better products</p>
    </div>
  </div>
</div>
                            
                        </div>
                        </div>
                    </div>
                <div class="col-12 col-sm-12 col-md-6  col-lg-6 col-xl-4">
                    <div class="login-white-box shadow-none py-0">
                        <div class="title">
                        <h3>Create Password</h3>
                            <p>Please create a new password that you have not used previously</p>
                        </div>
                <form onSubmit={submitHandler}>
                <div class="form-group">
                         <div class="input-group">
  <span class="input-group-text" id="basic-addon1"><img src="images/lock.svg"/></span>
            <input type="password" class="form-ctrl form-control" placeholder="New Password" onChange={(evt) => setRequest("password", evt)}/>
                        </div>
                    </div>
                     <div class="form-group">
                         <div class="input-group">
  <span class="input-group-text" id="basic-addon1"><img src="images/lock.svg"/></span>
            <input type="password" class="form-ctrl form-control" placeholder="Confirm Password" onChange={(evt) => setRequest("confirm_password", evt)} />
                        </div>
                    </div>
                    <div class="list-point pass-point">
                        <ul class="list-unstyled">
                        <li>At least 8 characters</li>
                        <li>A Combination of both uppercase and lowercase lelers</li>
                        <li>A Combination of letters and numbers</li>
                        <li>At least one special character, e.g., ! @ # ? ]</li>
                        </ul>
                        </div>
                    
                <div class="form-group">
           <button type="submit" class="btn btn-purple btn-submit btn-block">Submit</button>
          </div>
        </form>
                        
                    </div>
                    </div>
                </div>
        </div>
        <div class="login-mid-img create-pass-man">
        <img src="images/create-pass-man-img.png"/>
        </div>
        </div>
    </section>

	</>
  )
}

export default CreatePassword