import Axios from "axios";
//import swal from 'sweetalert';
import Constant from "../Components/Constant";
import loginUser from "./loginUser";
import {toast} from "react-toastify";

// 1)api url 2) post parameter 3)paramter for any this return or send alert : if true then function return value
export default function getApiCall(Url, params={},isReturn=false) {
    let {accessToken} = loginUser();
   return new Promise((resolve, reject) => { 
       Axios.get(Constant.apiBasePath + Url, {headers: {authorization: accessToken}, params:params})
        .then(data => {
           if(isReturn){
            if (!data.data.status) {
                toast.error(data.data.message);
            }
            resolve(data.data);
        }else{
            resolve(data.data);
        }
        })
        .catch(err => {
            if(err.response.status === 401){
                sessionStorage.clear();
                window.location.href = '/';
                return false;
            }
            if(err.response.data.message != undefined){
                alert(err.response.data.message);
                return false;
            }else{
                alert(err.response.data.message);
                return false;
            }
        })

   })

}


