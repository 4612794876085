import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardNav from "../includes/DashboardNav";
import SidebarNav from "../includes/SidebarNav";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import getApiCall from "../../Services/getApiCall";


const AboutUs = () => {
  const [request, setRequestParms] = useState({})
  const [pages, setPages] = useState({})

  useEffect(() => {
		getPages();
  }, [])
  
	async function getPages() {
        let data = await getApiCall('master/cms?slug=about-us', {}, true);
        if (data.status) {
			setPages(data.data);
        } else {
            toast.error(data.message != undefined ? data.message : "Network error!!")
        }
    }


  return (
	<>
		<DashboardNav/>
		<div class="main">
		<SidebarNav/>
			<div class="content">
			<p>{pages?.title}</p>
			{pages?.description}

			</div>
		</div>
   

</>
  )
}

export default AboutUs