import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardNav from "../includes/DashboardNav";
import SidebarNav from "../includes/SidebarNav";



const TestHistory = () => {
  const [request, setRequestParms] = useState({})
  const [validation, setValidation] = useState({})
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    
  }, [])


  return (
	<>
		<DashboardNav/>
		<div class="main">
		<SidebarNav/>
			<div class="content">
        <div>
            <div class="dashboardMain">
                <div class="test-dashboard-card">
                <h4>Test History</h4>
                    <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div class="media-dashboard media-dashboard-2 shadow-none bg-light-purple">
                <div class="media">
                    <div class="media-left">
                    <div class="dashboard-icon">
                        <img src="images/carbon_task-complete.svg"/>
                        </div>
                    </div>
                <div class="media-body">
                    <p class="large mb-0 text-purple">Completed Tests</p>
                    <h3 class="mb-0 text-purple">01</h3>
                    </div>
                </div>
                </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div class="media-dashboard media-dashboard-2 shadow-none bg-light-success">
                        <div class="media">
                    <div class="media-left">
                    <div class="dashboard-icon">
                        <img src="images/payment-icon.svg"/>
                        </div>
                    </div>
                <div class="media-body">
                <p class="large mb-0 text-success">Total Paid</p>
                    <h3 class="mb-0 text-success"><i class="fa fa-inr me-1"></i>1523</h3>
                </div>
                </div>
                </div>
                        </div>
                        
                         </div>
                         </div>
                
                
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="media-dashboard bg-white">
                                    <div class="mb-2">
                                        <div class="media-dashboard-header">
                                        <p class="small text-dark"><strong>#54233</strong></p>
                                        </div>
                                         <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                                         <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                                        <p><strong class="text-dark">Time taken:</strong> 130 Min</p>
                        <p><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">Payment Status: </strong> <span class="text-success"><i class="fa fa-inr me-1"></i>250</span> <span class="text-warning">Pending</span> </p>
                        <p><strong class="text-dark">Rating:  </strong> <i class="fa fa-star me-1"></i> <i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i> </p>
                                        
                                        <div class="grouptwobtns d-flex align-items-center">
                         <a href="#TestDetailsModal" data-bs-toggle="modal" data-bs-toggle="modal" class="btn btn-block btn-link text-purple fw600">View Details</a>
                </div>
                                    </div>
                    
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="media-dashboard bg-white">
                                    <div class="mb-2">
                                        <div class="media-dashboard-header">
                                        <p class="small text-dark"><strong>#54233</strong></p>
                                        </div>
                                         <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                                         <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                                        <p><strong class="text-dark">Time taken:</strong> 130 Min</p>
                        <p><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
                       <p><strong class="text-dark">Payment Status: </strong> <span class="text-success"><i class="fa fa-inr me-1"></i>250</span> <span class="text-warning">Pending</span> </p>
                        <p><strong class="text-dark">Rating:  </strong> <i class="fa fa-star me-1"></i> <i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i> </p>
                            <div class="grouptwobtns d-flex align-items-center">
                         <a href="#TestDetailsModal" data-bs-toggle="modal" data-bs-toggle="modal" class="btn btn-block btn-link text-purple fw600">View Details</a>
                            </div>
                                    </div>
                    
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="media-dashboard bg-white">
                                    <div class="mb-2">
                                        <div class="media-dashboard-header">
                                        <p class="small text-dark"><strong>#54233</strong></p>
                                        </div>
                                         <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                                         <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                                        <p><strong class="text-dark">Time taken:</strong> 130 Min</p>
                        <p><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">Payment Status: </strong> <span class="text-success"><i class="fa fa-inr me-1"></i>250</span> <span class="text-warning">Pending</span> </p>
                        <p><strong class="text-dark">Rating:  </strong> <i class="fa fa-star me-1"></i> <i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i> </p>
                            <div class="grouptwobtns d-flex align-items-center">
                         <a href="#TestDetailsModal" data-bs-toggle="modal" data-bs-toggle="modal" class="btn btn-block btn-link text-purple fw600">View Details</a>
                            </div>
                                    </div>
                    
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="media-dashboard bg-white">
                                    <div class="mb-2">
                                        <div class="media-dashboard-header">
                                        <p class="small text-dark"><strong>#54233</strong></p>
                                        </div>
                                         <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                                         <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                                        <p><strong class="text-dark">Time taken:</strong> 130 Min</p>
                        <p><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">Payment Status: </strong> <span class="text-success"><i class="fa fa-inr me-1"></i>250</span> <span class="text-warning">Pending</span> </p>
                        <p><strong class="text-dark">Rating:  </strong> <i class="fa fa-star me-1"></i> <i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i> </p>
                            <div class="grouptwobtns d-flex align-items-center">
                         <a href="#TestDetailsModal" data-bs-toggle="modal" data-bs-toggle="modal" class="btn btn-block btn-link text-purple fw600">View Details</a>
                            </div>
                                    </div>
                    
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="media-dashboard bg-white">
                                    <div class="mb-2">
                                        <div class="media-dashboard-header">
                                        <p class="small text-dark"><strong>#54233</strong></p>
                                        </div>
                                         <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                                         <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                                        <p><strong class="text-dark">Time taken:</strong> 130 Min</p>
                        <p><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">Payment Status: </strong> <span class="text-success"><i class="fa fa-inr me-1"></i>250</span> <span class="text-warning">Pending</span> </p>
                        <p><strong class="text-dark">Rating:  </strong> <i class="fa fa-star me-1"></i> <i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i> </p>
                            <div class="grouptwobtns d-flex align-items-center">
                         <a href="#TestDetailsModal" data-bs-toggle="modal" data-bs-toggle="modal" class="btn btn-block btn-link text-purple fw600">View Details</a>
                            </div>
                                    </div>
                    
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="media-dashboard bg-white">
                                    <div class="mb-2">
                                        <div class="media-dashboard-header">
                                        <p class="small text-dark"><strong>#54233</strong></p>
                                        </div>
                                         <h6>Lorem ipsum dolor sit amet consectetur adipiscing</h6>
                                         <p class="small">Lorem ipsum dolor sit amet consectetur adipiscing</p>
                                        <p><strong class="text-dark">Time taken:</strong> 130 Min</p>
                        <p><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
                        <p><strong class="text-dark">Payment Status: </strong> <span class="text-success"><i class="fa fa-inr me-1"></i>250</span> <span class="text-warning">Pending</span> </p>
                        <p><strong class="text-dark">Rating:  </strong> <i class="fa fa-star me-1"></i> <i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i><i class="fa fa-star me-1"></i> </p>
                            <div class="grouptwobtns d-flex align-items-center">
                         <a href="#TestDetailsModal" data-bs-toggle="modal" data-bs-toggle="modal" class="btn btn-block btn-link text-purple fw600">View Details</a>
                            </div>
                                    </div>
                    
                  </div>
                </div>
            </div>
                <nav aria-label="Page navigation example">
  <ul class="pagination justify-content-center navigation-number">
    <li class="page-item">
      <a class="page-link page-link-prev" href="#" tabindex="-1" aria-disabled="true">Prev</a>
    </li>
    <li class="page-item active"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
      <li class="page-item"><a class="page-link" href="#">...</a></li>
    <li class="page-item"><a class="page-link" href="#">50</a></li>
    <li class="page-item">
      <a class="page-link page-link-next" href="#">Next</a>
    </li>
  </ul>
</nav>
            </div>
            </div>


				
<div class="modal fade" id="TestDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-smm modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header justify-content-center border-0 text-center">
<h4 class="modal-title w-100">Test Details</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
           <div class="row justify-content-center">
               <div class="col-12 col-sm-10 col-md-10">
          <div class="profile-info">
                <p class="large"><strong class="text-dark">Test ID: </strong> #54233</p>
          </div>
               <div class="profile-info">
                        <p class="large"><strong class="text-dark">End Time:</strong> 12/05/2024, 02:00 pm</p>
              </div>
                    <div class="profile-info">
                        <p class="large"><strong class="text-dark">Platform:  </strong> Mobile App</p>
                  </div>
          <div class="profile-info">
                        <p class="large"><strong class="text-dark">Time Taken: </strong> 130 min</p>
                  </div>
        </div>
             </div>
        </div>
          </div>
          </div>
          </div>

   

		
		</div>
		</div>
   
</>
  )
}

export default TestHistory