//import logo from './logo.svg';
//import './App.css';
import React, {Component, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

import Login from "./Pages/auth/Login";
import Register from "./Pages/auth/Register";
import CreatePassword from "./Pages/auth/CreatePassword";
import DemoTest from "./Pages/demo/DemoTest";
import Dashboard from "./Pages/dashboard/Dashboard";
import TestQuestion from "./Pages/testquestion/TestQuestion";
import TestHistory from "./Pages/testhistory/TestHistory";
import Settings from "./Pages/settings/Settings";
import ContactUs from "./Pages/contactus/ContactUs";
import AboutUs from "./Pages/aboutus/AboutUs";
import PrivacyPolicy from "./Pages/privacypolicy/PrivacyPolicy";
import DeleteAccount from "./Pages/deleteaccount/DeleteAccount";
import ConfirmDeleteAccount from "./Pages/deleteaccount/ConfirmDeleteAccount";

import WebsiteAccess from "./WebsiteAccess";

let token = sessionStorage.getItem('loginDetails');

class App extends Component {
	
	render() {
        return (
            <Router>
                <Routes>
                    <Route exact strict path="/" element={token ? <Navigate to="/dashboard" /> :<Login/>}/>
					<Route exact strict path="/login" element={token ? <Navigate to="/dashboard" /> :<Login/>}/>
					<Route exact strict path="/register" element={token ? <Navigate to="/dashboard" /> :<Register/>}/>
					<Route exact strict path="/create-password" element={<CreatePassword/>}/>
					<Route exact strict path="/delete-account" element={<DeleteAccount/>}/>
					<Route exact strict path="/delconfirm-account/:id" element={<ConfirmDeleteAccount/>}/>
					
					<Route exact strict path="/test-question" element={<TestQuestion/>}/>
					<Route exact strict path="/test-history" element={<TestHistory/>}/>
					<Route exact strict path="/settings" element={<Settings/>}/>
					<Route exact strict path="/contactus" element={<ContactUs/>}/>
					<Route exact strict path="/aboutus" element={<AboutUs/>}/>
					<Route exact strict path="/privacy-policy" element={<PrivacyPolicy/>}/>
					
					<Route exact strict path="/dashboard" element={<WebsiteAccess element={<Dashboard />} />} />
					<Route exact strict path="/demo-test" element={<WebsiteAccess element={<DemoTest />} />} />
                    
                </Routes>
            </Router>

        )
    }
}

export default App;
