import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Logout, userDetails } from "../../Services/authenticationService";


const DashboardNav = () => {
  const [request, setRequestParms] = useState({})
  const [validation, setValidation] = useState({})
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    
  }, [])

	let userInfo=userDetails();

  return (
		
            <header class="header-after-login fixedHeader">
    <nav id="navbar-example2" class="navbar navbar-expand-lg  navbar-light bg-white">
        <div class="container-fluid">
           
            <a class="navbar-brand" href="/dashboard"><img src="images/logo.png"/></a>
      <ul class="navbar-nav ms-auto flex-nowrap d-flex mobile-nav align-items-center">
		<li class="nav-item login-drop dropdown">
           <a class="nav-link login dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"><img class="d-none" src="images/user-pic.png"/> <span>Hi {userInfo?.name}</span></a> 
		   <ul class="dropdown-menu dropdown-rgt" aria-labelledby="navbarDropdown" data-bs-popper="none">
                <li class="dropdown-header">
               <div class="d-flex">
                    
                    <div>
                   <h6>Hello {userInfo?.name}</h6>
                        <p>{userInfo?.email}</p>
                   </div>
                    </div>
               </li>
            <li><a class="dropdown-item" href="#">
                 <div class="d-flex justify-content-between align-items-center">
                    <div>
                <img src="images/star-purple.svg"/> Ratings
                     </div>
                     <div class="text-purple">
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o"></span>
                        <span>(4)</span>
                     </div>
                </div>
                     </a></li>
               
                  <li><a class="dropdown-item" href="#" onClick={Logout}>
                      <div class="d-flex justify-content-between align-items-center">
                    <div>
                <img src="images/logout-purple.svg"/>  Logout
                     </div>
                        <span class="fa fa-angle-right"></span>
                </div>
                     </a> </li>
          </ul>
             
        </li>
           
      </ul>
            <div class="navigation navigation-fixed">
            <img src="images/navigation.png"/>
            </div>
        </div>
</nav>
</header>
            
   


  )
}

export default DashboardNav