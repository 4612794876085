const ENVIRONMENT = 'DEV';

const Constant = {
    APP_NAME: "usertestpro",
    'statusSuccess': 'Success',
    'statusFailure': 'Failure',
    'perPage': 10,
    "successCode": 200,
    "updateSuccessCode": 202,
}

const basePath = {
   PROD: {
      apiBasePath: "http://13.234.187.136:7077/api/v1/"
   },
   DEV:{
      apiBasePath: "https://usertestproapi.b2cinfohosting.in/api/v1/"
   },
   LOCAL:{
      apiBasePath: "http://localhost:7077/api/v1/"
   },
}

if (ENVIRONMENT === 'PROD')
      Constant.apiBasePath = basePath["PROD"].apiBasePath;
else if(ENVIRONMENT === 'DEV')
      Constant.apiBasePath = basePath["DEV"].apiBasePath;
else if(ENVIRONMENT === 'LOCAL')
      Constant.apiBasePath = basePath["LOCAL"].apiBasePath;

module.exports = Constant;