import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardNav from "../includes/DashboardNav";
import SidebarNav from "../includes/SidebarNav";
import { userDetails } from "../../Services/authenticationService";



const Settings = () => {
  const [request, setRequestParms] = useState({})
  
  useEffect(() => {
    
  }, [])

	let userInfo=userDetails();
	
  return (
	<>
		<DashboardNav/>
		<div class="main">
		<SidebarNav/>
			<div class="content">
       <div>
            <div class="dashboardMain">
               <div class="bg-purple-light account_dashboard">
               <div class="form-box">
               <div class="login-white-box">
                   <div class="row">
                    <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-ctrl form-control" placeholder="Anuje" value={userInfo?.name}  disabled="disabled" />
                        </div>
                  </div>
                       <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div class="form-group">
                            <label>Registered Email</label>
                            <input type="text" class="form-ctrl form-control" placeholder="anuje123@gmail.com" value={userInfo?.email}  disabled="disabled" />
                        </div>
                        </div>
                  </div>
                  
                   <div class="row">
                       <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div class="form-group">
                            <label>Payment details</label>
                            <input type="text" class="form-ctrl form-control" placeholder="Payment ID" value={userInfo?.upi_id}  disabled="disabled" />
                        </div>
                        </div>
                  
                      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div class="form-group calendarInput">
                   <label>Date of Birth</label>
                       <input type="text" class="form-ctrl form-control" name="date" value="10/24/1984" placeholder="31-10-1984" value={userInfo?.dob}  disabled="disabled" />
                </div>
                    </div>
					</div>
					</div>
                  </div>
                   
                  </div>
                  </div>
                  </div>
           
            </div>
</div>



</>
  )
}

export default Settings